import React from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { ErrorView } from "@components";

const ErrorNotFound = () => {
    const router = useRouter();
    return (
        <div>
            <Head>
                <title>404 Not Found | RoboEpics</title>
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            </Head>
            <ErrorView url={router.asPath.toString()} statusCode={404} errorMessage="Not Found" />
        </div>
    );
};

export default ErrorNotFound;
